<template>
  <div class="container-xl px-0 px-sm-3">
    <div class="encabezado-pagina">
      <h2 class="col user-select-none">Contáctanos</h2>
    </div>
    <section v-if="agencia">
      <div class="encabezado-pagina">
        <h3 class="col user-select-none">{{agencia.nombre}}</h3>
      </div>
      <ul class="redes-sociales" v-if="agencia && agencia.telefonos && agencia.telefonos.length ">
        <li v-for="(telefono, i) in agencia.telefonos" :key="telefono.numero+i">
          <a
            :href="`https://wa.me/${telefono.numero}`"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div class="contenido">
              <font-awesome-icon :icon="['fab', 'whatsapp']" size="3x" />
              <span class="nombre-red">What's App</span>
              <span class="nombre-usuario">+{{telefono.numero}}</span>
            </div>
          </a>
        </li>
        <li v-for="(telefono, i) in agencia.telefonos" :key="telefono+i">
          <a
            :href="`sms:+${telefono.numero}`"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div class="contenido">
              <font-awesome-icon :icon="['fa', 'sms']" size="3x" />
              <span class="nombre-red">SMS</span>
              <span class="nombre-usuario">+{{telefono.numero}}</span>
            </div>
          </a>
        </li>
      </ul>
      <div v-else class="container justify-content-center text-center">
        <div class="text-muted mt-3">La agencia no cuenta con teléfonos disponibles</div>
      </div>
    </section>
  </div>
</template>
<script>
import clienteContactoGql from '@/graphql/clienteContacto.gql'
import { leerPersonaId } from '@/utils/datosToken.js'
export default {
  name: 'Comunicarme',
  apollo: {
    agencia () {
      return {
        query: clienteContactoGql,
        variables () {
          return {
            filter: {
              id: leerPersonaId()
            }
          }
        },
        update: (data) => data.Cliente[0].agencia,
        fetchPolicy: 'cache-and-network'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.redes-sociales {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  list-style: none;
  margin-block-end: 0;
  margin-top: 1rem;
  padding-inline-start: 0;

  @media screen and (min-width: 576px){flex-flow: row wrap;}

  li {
    @media screen and (min-width: 576px){width: calc(100% / 2);}
    @media screen and (min-width: 992px){width: calc(100% / 3);}
    @media screen and (min-width: 1300px){width: calc(100% / 4);}
  }
  .contenido {
    border: 1px solid #E0E2EC;
    border-radius: .125em;
    color: $terciario-sombra;
    cursor: pointer;
    margin: .75rem 1rem;
    padding: 1rem .48rem;
    text-align:center;
    transition-property: border-color,box-shadow, color;
    transition-duration: .25s;
    transition-timing-function: ease;
    user-select: none;

    &:hover {
      border-color: #C1C6DA;
      box-shadow: 3px 3px 10px #E0E2EC;
      color: var(--terciario-tinte, $terciario-tinte);
    }
  }

  .nombre-red {
    display: block;
    font-weight: 500;
  }
}
</style>
